.standard-survey-form-container {
    line-height: 1.2 !important;
    font-size: 14px !important;
}

.standard-survey-form-container p {
    font-size: 14px !important;
    margin: 0;
}

.standard-survey-form-container ul {
    margin: 0;
}

.standard-survey-form-container h3 {
    font-size: 14px !important;
}

.standard-survey-form-container label {
    font-size: 14px !important;
}

.standard-survey-form-container th {
    font-size: 12px !important;
    font-weight: normal;
}

.standard-survey-form-container .standard-input-cell {
    min-width: 100px;
    width: 100px;
    text-align: center;
}

.standard-survey-form-container .standard-outlined-input {
    padding: 8px 4px 8px 4px !important
}

.standard-survey-form-container .MuiInputBase-multiline {
    padding: 8px !important;
}