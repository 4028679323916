.print-survey-form-container {
    line-height: 1.2 !important;
    font-size: 10px !important;
}

.print-survey-form-container p {
    font-size: 10px !important;
    margin: 0;
}

.print-survey-form-container ul {
    margin: 0;
}

.print-survey-form-container h2 {
    font-size: 10px !important;
}

.print-survey-form-container h3 {
    font-size: 10px !important;
}

.print-survey-form-container label {
    font-size: 10px !important;
}

.print-survey-form-container th {
    font-size: 8px !important;
    font-weight: normal;
}

.print-survey-form-container td {
    font-size: 8px !important;
    font-weight: normal;
}

.print-survey-form-container input {
    font-size: 10px !important;
}

.print-survey-form-container .MuiInputBase-multiline {
    padding: 4px 8px !important;
}

.print-survey-form-container textarea {
    font-size: 10px !important;
    line-height: 1.2;
}

.print-survey-form-container .standard-input-cell {
    min-width: 100px;
    width: 100px;
    text-align: center;
}

.print-survey-form-container .standard-outlined-input {
    padding: 0 !important;
    text-align: center;
}